define("discourse/plugins/discourse-rewind/discourse/connectors/before-panel-body/rewind-callout", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/d-icon", "discourse/lib/key-value-store", "discourse/plugins/discourse-rewind/discourse/lib/is-rewind-active", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dIcon, _keyValueStore, _isRewindActive, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RewindTab extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    store = (() => new _keyValueStore.default("discourse_rewind_" + this.fetchYear))();
    get showCallout() {
      return (0, _isRewindActive.default)() && !this.dismissed;
    }
    get fetchYear() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      if (currentMonth === 0) {
        return currentYear - 1;
      } else {
        return currentYear;
      }
    }
    get dismissed() {
      return this.store.getObject("_dismissed") ?? false;
    }
    openRewind() {
      this.store.setObject({
        key: "_dismissed",
        value: true
      });
      this.router.transitionTo("/my/activity/rewind");
    }
    static #_2 = (() => dt7948.n(this.prototype, "openRewind", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showCallout}}
          <div class="rewind-callout__container">
            <DButton
              @action={{this.openRewind}}
              class="rewind-callout btn-transparent"
            >
              <img
                class="rewind-logo -light"
                src="/plugins/discourse-rewind/images/discourse-rewind-logo.png"
              />
              <img
                class="rewind-logo -dark"
                src="/plugins/discourse-rewind/images/discourse-rewind-logo-dark.png"
              />
    
              {{icon "arrow-right" class="rewind-callaout__arrow"}}
            </DButton>
          </div>
        {{/if}}
      
    */
    {
      "id": "lauFVDlr",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showCallout\"]],[[[1,\"      \"],[10,0],[14,0,\"rewind-callout__container\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"rewind-callout btn-transparent\"]],[[\"@action\"],[[30,0,[\"openRewind\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,\"img\"],[14,0,\"rewind-logo -light\"],[14,\"src\",\"/plugins/discourse-rewind/images/discourse-rewind-logo.png\"],[12],[13],[1,\"\\n          \"],[10,\"img\"],[14,0,\"rewind-logo -dark\"],[14,\"src\",\"/plugins/discourse-rewind/images/discourse-rewind-logo-dark.png\"],[12],[13],[1,\"\\n\\n          \"],[1,[28,[32,1],[\"arrow-right\"],[[\"class\"],[\"rewind-callaout__arrow\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-rewind/discourse/connectors/before-panel-body/rewind-callout.js",
      "scope": () => [_dButton.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RewindTab;
});